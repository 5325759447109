import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { ProductRecord } from "../types";

export const useProductsStore = defineStore('products', {
    state: () => ({
        pages: [] as PaginatedData<ProductRecord>[],
        filters: {
            searchTerm: '',
            pageNumber: 1,
            pageSize: 20,
            onlyConsumerProduct: false,
            productCategoryIds: [] as string[],
            ingredientIds: [] as string[],
            brandIds: [] as string[],
            detailIds: [] as string[],
        },
        busyStates: {
            isLoading: false,
            isLoadingNextPage: false
        },
        listLastScrollY: 0
    }),
    getters: {
        products: state => state.pages.flatMap(x => x.items)
    },
    actions:{
        async getProducts (
            searchTerms = '',
            onlyConsumerProduct = false,
            productCategoryIds = [] as string[],
            ingredientIds = [] as string[],
            brandIds = [] as string[],
            detailIds = [] as string[],
        ) {
            this.$patch({
                filters: {
                    pageNumber: 1,
                    searchTerm: searchTerms,
                    onlyConsumerProduct,
                    productCategoryIds,
                    ingredientIds,
                    brandIds,
                    detailIds,
                },
                pages: [],
                busyStates: {
                    isLoading: true
                }
            })
            try {
                const request = axios.get<APIResponse<PaginatedData<ProductRecord>>>(
                    `${endpoints.PRODUCTS}/paginated`,
                    { params: this.filters }
                );
                const response = await useErrorHandling(request)
                this.pages = [response.data.data];
            } finally {
                this.busyStates.isLoading = false;
            }
        },
        async loadNextPage () {
            this.$patch({
                filters: {
                    pageNumber: this.filters.pageNumber + 1,
                    onlyConsumerProduct: this.filters.onlyConsumerProduct
                },
                busyStates: {
                    isLoadingNextPage: true
                }
            })
            try {
                const request = axios.get<APIResponse<PaginatedData<ProductRecord>>>(
                    `${endpoints.PRODUCTS}/paginated`,
                    { params: this.filters }
                );
                const response = await useErrorHandling(request)
                this.pages.push(response.data.data);
            } finally {
                this.busyStates.isLoadingNextPage = false;
            }
        }
    }
})